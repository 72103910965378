@import "~@studyportals/styles-abstracts/abstracts.scss";

.Notification {
	height: fit-content;
	min-height: 50px;
	width: 350px;
	z-index: $FourthFloor;

	background-color: $White;
	border-radius: 0.5rem;
	box-shadow: 0 2px 2px 0 rgb(63 92 110 / 15%);

	display: flex;
	flex-direction: column;

	@include Breakpoint(Large, ExtraLarge) {
		position: fixed;
		right: 1rem;
		top: 1rem;
	}

	@include Breakpoint(Small, Medium) {
		width: 95%;
		margin: 0 auto;
		position: fixed;
		bottom: 1rem;
	}

	.TimerBarProgress {
		width: 100%;
		height: 0.5rem;
		display: flex;

		.TimerBar {
			width: 100%;
			height: 100%;
			border-bottom-left-radius: 0.5rem;
			border-bottom-right-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
		}
	}

	.NotificationWrapper {
		display: flex;
		flex-direction: row;
	
		.NotificationAccent {
			position: absolute;
			width: 0.5rem;
			height: 100%;
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
		}
	
		.NotificationContainer {
			display: flex;
			@include MarginAreas(0.5);
			@include TextStyle(Note);
	
			.NotficationImage {
				max-width: 80px;
				margin: auto;
			}
	
			.NotificationContent {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				@include MarginAreas(0.5);
	
				.NotificationText {
					text-align: left;
				}
	
				.NotificationButtons {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					@include MarginAreas(0.5,0,0,0);
				}
			}
		}
	}

	.action-primary {
		background-color: $BrandOrange
	}

	.action-secondary {
		background-color: $Yellow
	}

	.information {
		background-color: $Green
	}
}

.slide-in {
	animation: slide-in .8s ease;
}

.slide-out {
	animation: slide-out 1.5s ease;
}

@keyframes slide-in {
	from {
		transform: translateX(100%);
		opacity: 0;
	}
}

@keyframes slide-out {
	to {
		transform: translateX(200%);
		opacity: 0;
	}
}